import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Search from "@mui/icons-material/Search"; // Make sure to import the Search icon
import Clear from "@mui/icons-material/Clear"; // Import Clear icon
import { fetchDepartments } from "../../slices/api/departmentsApi";
import CategoryList from "./departments/CategoryList";
import { TextField, InputAdornment } from "@mui/material";

const Departments = () => {
  const [parent, setParent] = useState("main");
  const [searchTerm, setSearchTerm] = useState(""); // State for search term
  const dispatch = useDispatch();
  const { departmentsList } = useSelector((state) => state.departments);
  const { isAdmin, department } = useSelector((state) => ({
    isAdmin: state.auth.user.userInfo.isAdmin,
    department: state.auth.user.userInfo.department,
  }));

  useEffect(() => {
    if (isAdmin !== undefined && department) {
      fetchDepartments(dispatch, isAdmin, department);
    }
  }, [dispatch, isAdmin, department]);

  const handleGoBack = () => {
    setParent("main");
  };

  // Handle the search input change
  const handleSearch = (event) => {
    setSearchTerm(event.target.value.toLowerCase()); // Update search term to lowercase for case-insensitive search
  };

  // Filter the departments based on the search term
  const filteredDepartments = departmentsList.filter(
    (department) => department.name.toLowerCase().includes(searchTerm) // Match department names against search term
  );

  return (
    <div>
      {parent !== "main" ? (
        <div className="tab-header">
          <h4>
            <button type="button" className="back-btn" onClick={handleGoBack}>
              <ChevronLeftIcon />
            </button>
            Subcategories
          </h4>
        </div>
      ) : null}


      <TextField
        label="Search"
        variant="outlined"
        value={searchTerm}
        onChange={handleSearch}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {searchTerm && (
                <Clear
                  onClick={() => setSearchTerm("")}
                  style={{ cursor: "pointer" }}
                />
              )}
              <Search />
            </InputAdornment>
          ),
        }}
      />
      <br></br>
      <br></br>
      <CategoryList
        parent={parent}
        setParent={setParent}
        departmentsList={filteredDepartments} // Pass the filtered departments list
      />
    </div>
  );
};

export default Departments;
