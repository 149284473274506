import { configureStore } from "@reduxjs/toolkit";
import authSlice from "../slices/AuthSlice";
import globalSlice from "../slices/globalSlice";
import chatReducer from "../slices/ChatSlice";
import casesReducer from "../slices/CasesSlice";
import configReducer from "../slices/ConfigSlice";
import requesterSlice from "../slices/RequesterSlice";
import userSlice from "../slices/UserSlice";
import categorySlice from "../slices/CategorySlice";
import faqsSlice from "../slices/FAQSlice";
import departmentSlice from "../slices/DepartmentSlice";
import toastSlice from "../slices/ToastSlice";
import feedbackSlice from "../slices/FeedbackSlice";
import surveyAnswersSlice from "../slices/SurveysSlice";

const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    global: globalSlice.reducer,
    chat: chatReducer.reducer,
    cases: casesReducer.reducer,
    configs: configReducer.reducer,
    requester: requesterSlice.reducer,
    user: userSlice.reducer,
    category: categorySlice.reducer,
    faqs: faqsSlice.reducer,
    departments: departmentSlice.reducer,
    toast: toastSlice.reducer,
    feedback: feedbackSlice.reducer,
    surveys: surveyAnswersSlice.reducer,
  },
});

export default store;
