import React, { useEffect } from "react";
import Sidebar from "../components/Sidebar";
import TopNav from "../components/TopNav";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import { globalActions } from "../slices/globalSlice";
import UserDialog from "../components/UserDialog";
import Toast from "../components/common/Toast";

const HomePage = () => {
  const toast = useSelector((state) => state.toast);

  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    const currentUrl = location.pathname.split("/")[1];
    dispatch(globalActions.changeTab(currentUrl));
  }, []);

  return (
    <div>
      <div id="home_page">
        <UserDialog />
        <Sidebar />
        <div className="main-side">
          <TopNav />
          <div className="inner-container" id="new_comment">
            <Outlet />
          </div>

          {toast && toast.showToast && toast && toast.toastMessage != "" ? (
            <Toast />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default HomePage;
