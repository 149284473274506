import React from "react";
import { Autocomplete } from "@mui/material";
import TextField from "@mui/material/TextField";
import Constants from "../../../constants/Constants";

const DepartmentAutocomplete = ({
  departmentsList,
  selectedDepartment,
  handleDepartmentChange,
}) => {
  const options = [
    { id: "all", name: "All" },
    ...(departmentsList?.filter(
      (department) =>
        department.parentCategory === "main" &&
        department.status !== Constants.departmentsStatuses.DISABLED
    ) || []),
  ];

  return (
    <Autocomplete
      disablePortal
      options={options}
      getOptionLabel={(option) => option.name}
      value={
        options.find((department) => department.id === selectedDepartment) ||
        { id: "all", name: "All" }
      }
      onChange={(event, newValue) => {
        handleDepartmentChange(newValue ? newValue.id : "all"); // Handle selection
      }}
      sx={{ width: 300, marginLeft: "20px" }}
      renderInput={(params) => (
        <TextField {...params} label="Department" variant="outlined" />
      )}
    />
  );
};

export default DepartmentAutocomplete;
