import React, { useEffect, useState } from "react";
import { Pagination } from "@mui/material";
import CategoryCard from "./CategoryCard";
import SubCategoryList from "./SubCategoryList";

const ITEMS_PER_PAGE = 12;

const CategoryList = ({ parent, setParent, departmentsList }) => {
  const [subCategories, setSubCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const getSubCategories = (category) => {
    return departmentsList.filter(
      (categoryItem) => categoryItem.parentCategory === category.id
    );
  };

  const handleCategoryClick = (category) => {
    setParent(category.name);
    setSelectedCategory(category);
    const subCategories = getSubCategories(category);
    setSubCategories(subCategories);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  useEffect(() => {
    if (selectedCategory) {
      setParent(selectedCategory.name);
      setSelectedCategory(selectedCategory);
      const subCategories = getSubCategories(selectedCategory);
      setSubCategories(subCategories);
    }
  }, [departmentsList]);

  // Calculate the total number of pages based on the number of categories
  const totalPages = Math.ceil(
    departmentsList.filter((category) => category.parentCategory === "main")
      .length / ITEMS_PER_PAGE
  );

  // Paginate the departmentsList for the current page
  const paginatedCategories = departmentsList
    .filter((category) => category.parentCategory === "main")
    .slice((currentPage - 1) * ITEMS_PER_PAGE, currentPage * ITEMS_PER_PAGE);

  return (
    <div className="departments-list-main-container">
      {parent === "main" ? (
        <div className="departments-list-container">
          <div className="departments-list p-3">
            {paginatedCategories.map((category) => (
              <CategoryCard
                key={category.id}
                category={category}
                departmentsList={departmentsList}
                onClickCategory={handleCategoryClick}
              />
            ))}
          </div>
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
            sx={{ position: "absolute", bottom: 0, width: '100%' }} // Fixed position
          />
        </div>
      ) : (
        <SubCategoryList
          subCategories={subCategories}
          selectedCategory={selectedCategory}
        />
      )}
    </div>
  );
};

export default CategoryList;
