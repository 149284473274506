import React, { useEffect } from "react";
import DashboardTable from "../../components/charts/DashboardTable";
import PieChart from "../../components/charts/PieChart";
import BarChart from "../../components/charts/BarChart";
import StackedChart from "../../components/charts/StackedChart";
import { useDispatch, useSelector } from "react-redux";
import { subscribeToCases } from "../../slices/api/casesApi";
import { subscribeToDepartments } from "../../slices/api/departmentsApi";
import Constants from "../../constants/Constants";
import moment from "moment";

const options1 = {
  pieSliceText: "none",
  legend: "bottom",
};

const openByPriorityOptions = {
  pieSliceText: "none",
  colors: ["#e6e6e6", "#b3b3b3", "#ff6600", "#df2e2e", "#29bb89"],
  legend: "bottom",
};

const completedCasesOptions = {
  legend: { position: "none" },
  bar: { groupWidth: "70%" },
  colors: ["#29bb89"],
};

const Dashboard = () => {
  const dispatch = useDispatch();
  const cases = useSelector((state) => state.cases.casesList);
  const authUser = useSelector((state) => state.auth);
  const departmentsState = useSelector((state) => state.departments);

  // === Completed Cases in the last 30 days ===
  const today = moment();
  const thirtyDaysAgo = moment().subtract(30, "days");

  const last30Days = Array.from({ length: 30 }, (_, i) =>
    moment().subtract(i, "days").format("MMM DD")
  ).reverse();

  const closedCasesLast30Days = cases.filter(
    (item) =>
      (item.status === "closed" || item.status === "resolved") &&
      moment(item.dateUpdated).isBetween(thirtyDaysAgo, today, undefined, "[]") // inclusive range
  );

  const closedCasesByDay = closedCasesLast30Days.reduce((acc, curr) => {
    const closedDate = moment(curr.dateUpdated).format("MMM DD");
    return {
      ...acc,
      [closedDate]: (acc[closedDate] || 0) + 1,
    };
  }, {});

  const completedCasesData = last30Days.map((day) => {
    const completed = closedCasesByDay[day] || 0;
    return [day, completed];
  });

  useEffect(() => {
    let unsubscribe = false;

    if (authUser) {
      const isSuperAdmin = authUser.user.userInfo.isSuperAdmin;

      unsubscribe = subscribeToCases(
        dispatch,
        authUser.user.userInfo.department,
        isSuperAdmin
      );

      subscribeToDepartments(
        dispatch,
        isSuperAdmin,
        authUser.user.userInfo.department
      );
    }

    return () => {
      if (typeof unsubscribe === "function") {
        unsubscribe();
      }
    };
  }, [dispatch, authUser]);

  const completedData = [["Date", "Completed Cases"], ...completedCasesData];

  const countCasesByPriority = (priority) => {
    return cases.filter(
      (caseItem) =>
        caseItem.priority === priority &&
        caseItem.status !== Constants.caseStatuses.CLOSED
    ).length;
  };

  const openCases = cases.filter((item) => item.status !== "closed");
  const slaViolationCases = cases.filter((item) => item.slaEscalated === true);

  // === Open Cases by Priority ===
  const openByPriorityData = [
    ["Priority", "Cases"],
    ["Low", countCasesByPriority("low")],
    ["Medium", countCasesByPriority("medium")],
    ["High", countCasesByPriority("high")],
    ["Emergency", countCasesByPriority("emergency")],
    ["Normal", countCasesByPriority("normal")],
  ];

  // === Open Cases by Department ===
  const departmentCount = openCases.reduce((acc, curr) => {
    const departmentId = curr.department; // Assuming curr.department holds the department ID

    // Correctly find the department object by its ID
    const department = departmentsState.departmentsList.find(
      (department) => department.id === departmentId
    );
    const departmentName = department ? department.name : "General/Unknown"; // Fetch department name from state

    return {
      ...acc,
      [departmentName]: (acc[departmentName] || 0) + 1,
    };
  }, {});

  const departmentCasesCount = [["Department", "Cases Open"]].concat(
    Object.entries(departmentCount).map(([name, count]) => [name, count])
  );

  const notOverdue = openCases.length - slaViolationCases.length;

  const SLAViolatedCasesOptions = {
    legend: { position: "none" },
    isStacked: true,
    colors: ["#CCC", "#df2e2e"],
    hAxis: {
      title: "Total Cases",
      minValue: 0,
      maxValue: openCases.length,
    },
    bar: { groupWidth: "20%" },
  };

  const SLAViolatedCasesData = [
    ["cases", "Not Overdue", "Overdue"],
    ["", notOverdue, slaViolationCases.length],
  ];

  return (
    <div id="dashboard">
      <div className="header-part">
        <h2>Service Desk Dashboard</h2>
      </div>
      <div className="charts">
        <div className="chart-card shadow-sm">
          <div className="card-header">
            <h4>Cases by Channel</h4>
          </div>
          <div className="chart-container">
            <DashboardTable />
          </div>
        </div>
        <div className="chart-card shadow-sm">
          <div className="card-header">
            <h4>Open by Departments</h4>
          </div>
          <div className="chart-container">
            <PieChart
              categData={departmentCasesCount}
              categOptions={options1}
            />
          </div>
        </div>
        <div className="chart-card shadow-sm">
          <div className="card-header">
            <h4>Open Cases by Priority</h4>
          </div>
          <div className="chart-container">
            <PieChart
              categData={openByPriorityData}
              categOptions={openByPriorityOptions}
            />
          </div>
        </div>
        <div className="chart-card shadow-sm">
          <div className="card-header">
            <h4>SLA Violated Cases</h4>
          </div>
          <div className="chart-container">
            <StackedChart
              stackedChartData={SLAViolatedCasesData}
              stackedChartOptions={SLAViolatedCasesOptions}
            />
          </div>
        </div>
        <div className="chart-card shadow-sm">
          <div className="card-header">
            <h4>Completed Cases in last 30 days</h4>
          </div>
          <div className="chart-container">
            <BarChart
              barData={completedData}
              barOptions={completedCasesOptions}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
