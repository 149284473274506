import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  surveyAnswersList: [],
};

const surveyAnswersSlice = createSlice({
  name: "surveys",
  initialState,
  reducers: {
    setSurveyAnswersList: (state, action) => {
      state.surveyAnswersList = action.payload;
    },
  },
});

export const { setSurveyAnswersList } = surveyAnswersSlice.actions;

export default surveyAnswersSlice;
