import {
  collection,
  onSnapshot,
  query,
  updateDoc,
  doc,
  arrayUnion,
  orderBy,
} from "firebase/firestore";
import { db } from "../../firebase/firebase";
import { setFeedback } from "../FeedbackSlice";

export const updateFeedbackIsRead = async (feedbackId, userEmail) => {
  try {
    const feedbackRef = doc(db, "feedbacks", feedbackId);

    const updateData = {
      dateUpdated: new Date().toString(),
      feedbackReadByEmails: arrayUnion(userEmail),
    };

    await updateDoc(feedbackRef, updateData);
    console.log("Done");
  } catch (error) {
    console.error("Error updating feedback:", error);
  }
};

export const feedbackFetch = (dispatch) => {
  const feedbackRef = collection(db, "feedbacks");
  let q = query(feedbackRef, orderBy("dateCreated", "desc"));

  const unsubscribe = onSnapshot(q, (snapshot) => {
    const feedbackList = snapshot.docs.map((doc) => {
      let feedbackData = doc.data();
      return { id: doc.id, ...feedbackData };
    });

    dispatch(setFeedback(feedbackList));
  });

  return unsubscribe;
};
