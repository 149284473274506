import React from "react";
import { useDispatch, useSelector } from "react-redux";
import WindowIcon from "@mui/icons-material/Window";
import { authActions, loginAsyncWithMicrosoft } from "../slices/AuthSlice";
import { useNavigate } from "react-router-dom";
import UsersService from "../services/usersService";
import { getUserByUserPrincipalName } from "../slices/api/usersApi";

import WSULogo from "../assets/img/wsu_web_logo.png";

const Login = () => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.auth.user);
  const navigate = useNavigate();

  const handleLogin = async () => {
    console.log("Starting login mechanism.");
    const res = await dispatch(loginAsyncWithMicrosoft());
    const user = JSON.parse(res.payload);
    const rawuserInfo = JSON.parse(user._tokenResponse.rawUserInfo);
    const userResults = await getUserByUserPrincipalName(rawuserInfo.mail);
    dispatch(authActions.addUserInfo({ ...user, userInfo: userResults }));
    console.log("Finished loggin in...");
    navigate("/dashboard");
  };

  return (
    <div>
      <div id="welcome_page">
        <div className="welcome-nav">
          <div className="logo">
            {/* <span>
              <span></span>
            </span> */}
            <div>
              <img src={WSULogo} alt="" />
            </div>
            {/* <h4>MyWSU Service Desk</h4> */}
          </div>
          <div className="nav-menu">
            <button type="button">Contact</button>
            <span className="v-divider"></span>
            <button type="button">User Guide</button>
          </div>
        </div>
        <div className="welcome-centent">
          <div className="hero-part">
            <h1>
              Experience Real-Time, Efficient Support.{" "}
              <span>A Cutting-Edge Service Desk</span>
            </h1>
            <p>
              Leverage modern cloud technology with our omni-channel
              capabilities, including WhatsApp, Web, Email, and SMS. Our
              AI-powered tools ensure efficient and seamless customer support.
              Manage tickets, incidents, and service requests with ease. Enjoy
              comprehensive chat support, incident management, dashboards, and a
              robust FAQ system.
            </p>
            <button className="main-btn" onClick={handleLogin}>
              <WindowIcon /> <span>Sign in with Microsoft</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
