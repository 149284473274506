import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import CommentIcon from "@mui/icons-material/Comment";
import DescriptionIcon from "@mui/icons-material/Description";
import MyEditor from "../../Editor";
import DragAndDrop from "../../DragAndDropBox";
import StorageService from "../../../services/storageService";
import Constants from "../../../constants/Constants";
import { v4 as uuidv4 } from "uuid";
import {
  sendCaseUpdateEmail,
  updateCaseWithComment as updateCaseWithCommentApi,
} from "../../../slices/api/casesApi";
import { setActiveCase } from "../../../slices/CasesSlice";
import {
  setShowToast,
  setToastMessage,
  setToastTimer,
} from "../../../slices/ToastSlice";
import NameResolver from "../../common/NameResolver";
import getRelativeTime from "../../../helpers/timeFromNow";

const ConversationTab = () => {
  const storageService = new StorageService();
  const dispatch = useDispatch();
  const authenticatedUser = useSelector((state) => state.auth.user);
  const cases = useSelector((state) => state.cases);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [comment, setComment] = useState("");
  const [files, setFiles] = useState([]);

  // Memoizing user roles to prevent redundant checks
  const isUserAdminOrAssignee = useMemo(() => {
    const isAdmin =
      authenticatedUser.userInfo.isAdmin ||
      authenticatedUser.userInfo.isSuperAdmin;
    const isAssignee = cases.activeCase?.assignedTo?.includes(
      authenticatedUser.userInfo.mail
    );
    return isAdmin || isAssignee;
  }, [authenticatedUser.userInfo, cases.activeCase]);

  const updateCaseWithComments = async (event) => {
    event.preventDefault();

    if (!comment && files.length === 0) return;

    setIsSubmitting(true);

    const updateCaseData = {
      commentId: uuidv4(),
      dateCreated: new Date().toString(),
      isRead: false,
      commenter: Constants.messageSender.AGENT,
      commenterId: authenticatedUser.userInfo.mail,
      commentContent: comment || "",
      commentAttachmentsIds: [],
    };

    try {
      if (files.length > 0) {
        // Upload files concurrently
        const uploadPromises = files.map((file) =>
          storageService.uploadFile(file, Constants.typeOfService.CASES)
        );
        const uploadedFiles = await Promise.all(uploadPromises);
        updateCaseData.commentAttachmentsIds = uploadedFiles;
      }

      await updateCaseWithCommentApi(cases.activeCase.id, updateCaseData);

      // Send case update email
      if (comment) {
        await sendCaseUpdateEmail(
          [cases.activeCase.caseRequester],
          cases.activeCase.caseNumber,
          comment,
          cases.activeCase.id
        );
      }

      setComment("");
      setFiles([]);
      setIsSubmitting(false);
    } catch (error) {
      console.error(error);
      dispatch(setToastMessage("An error occurred while updating the case"));
      dispatch(setToastTimer(3000));
      dispatch(setShowToast(true));
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (cases.activeCase) {
      // alert("Changes")
      const updatedActiveCase = cases.casesList.find(
        (item) => item.id === cases.activeCase.id
      );
      if (updatedActiveCase) {
        dispatch(setActiveCase(updatedActiveCase));
      }
    }
  }, [cases, dispatch]);

  return (
    <div className="conversation-tab">
      <div className="timeline-container">
        {cases.activeCase.caseDescription && (
          <div className="description comment">
            <div className="timeline-indicator">
              <span className="comm-type">Description</span>
              <span className="circle">
                <DescriptionIcon fontSize="inherit" />
              </span>
            </div>
            <div className="comment-header">
              <p>
                <span className="global-name">
                  <NameResolver email={cases.activeCase.caseRequester} />
                </span>{" "}
                -{" "}
                {moment
                  .utc(new Date(cases.activeCase.dateCreated))
                  .add(2, "hours")
                  .format("MMM DD, YYYY HH:mm")}
              </p>
            </div>
            <div
              className="comment-content"
              dangerouslySetInnerHTML={{
                __html: cases.activeCase.caseDescription,
              }}
            ></div>
          </div>
        )}
        {cases.activeCase.comments.length === 0 ? (
          <div>No comments</div>
        ) : (
          cases.activeCase.comments.map((comment) => (
            <div key={comment.commentId} className="comment">
              <div className="timeline-indicator">
                <span className="comm-type">{getRelativeTime(comment.dateCreated)}</span>
                <span className="circle">
                  <CommentIcon fontSize="inherit" />
                </span>
              </div>
              <div className="comment-header">
                <p>
                  <span className="global-name">
                    <NameResolver email={comment.commenterId} />
                  </span>{" "}
                  -{" "}
                  {moment
                    .utc(new Date(comment.dateCreated))
                    .add(2, "hours")
                    .format("MMM DD, YYYY HH:mm")}
                </p>
              </div>
              {comment.commentContent && (
                <div
                  className="comment-content"
                  dangerouslySetInnerHTML={{
                    __html: comment.commentContent,
                  }}
                ></div>
              )}
              {comment.commentAttachmentsIds.length > 0 && (
                <div className="comment-attachments">
                  <h4>Attachments</h4>
                  {comment.commentAttachmentsIds.map((attachmentUrl, index) => (
                    <a
                      key={index}
                      href={attachmentUrl}
                      className="file-item shadow-sm me-3"
                      download
                    >
                      {attachmentUrl}
                    </a>
                  ))}
                </div>
              )}
            </div>
          ))
        )}
      </div>
      {cases.activeCase.status === Constants.caseStatuses.OPEN && isUserAdminOrAssignee && (
        <div className="comment-sec shadow">
          <h4>New Comment</h4>
          <MyEditor
            comment={comment}
            setComment={setComment}
            isEditable={isUserAdminOrAssignee}
            id="add_new_comment"
          />
          <h5 className="attachments-header">Attachments</h5>
          <DragAndDrop files={files} setFiles={setFiles} isEditable={isUserAdminOrAssignee} />
          <div className="btns-container">
            <button
              type="button"
              className="main-btn"
              disabled={isSubmitting || !isUserAdminOrAssignee}
              onClick={updateCaseWithComments}
            >
              {isSubmitting ? "Updating..." : "Save"}
            </button>
            <button
              type="button"
              disabled={!isUserAdminOrAssignee}
              className="no-border-btn"
            >
              Cancel
            </button>
          </div>
        </div>
      )}
      {cases.activeCase.status !== Constants.caseStatuses.OPEN && <p>Case closed, cannot comment on closed case.</p>}
    </div>
  );
};

export default ConversationTab;
