import React, { useEffect } from "react";
import DataTable from "./users/DataTable";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllUsers,
  getUsersByDepartmentId,
  getUsersByKeyword,
} from "../../slices/api/usersApi";
import { setUsers } from "../../slices/UserSlice";

const Users = () => {
  const authUser = useSelector((state) => state.auth);
  const users = useSelector((state) => state.user.users);
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = React.useState("");
  const [selectedDepartment, setSelectedDepartment] = React.useState("all");

  const fetchUsers = async () => {
    if (selectedDepartment === "all") {
      const endsWithKeyword = "@wsu.ac.za";
      const usersResultsFromAd = await getUsersByKeyword(
        searchTerm !== "" ? searchTerm : null,
        endsWithKeyword
      );
      const filteredUsersFromAd = usersResultsFromAd.data.value.filter(
        (user) => {
          // Filter out users without givenName or if givenName is empty, null, or undefined
          return user.givenName && user.givenName.trim() !== "";
        }
      );

      dispatch(setUsers(filteredUsersFromAd));
    } else {
      const usersResultsFromDb = await getUsersByDepartmentId(
        selectedDepartment
      );

      const filteredUsersFromDb = usersResultsFromDb.filter((user) => {
        const { displayName, mail, surname, givenName, userPrincipalName } =
          user;

        // Filter out users without givenName or if givenName is empty, null, or undefined
        if (!givenName || givenName.trim() === "") return false;

        // Filter by search term
        return (
          (displayName &&
            displayName.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (surname &&
            surname.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (givenName &&
            givenName.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (userPrincipalName &&
            userPrincipalName
              .toLowerCase()
              .includes(searchTerm.toLowerCase())) ||
          (mail && mail.toLowerCase().includes(searchTerm.toLowerCase()))
        );
      });

      dispatch(setUsers(filteredUsersFromDb));
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [dispatch, authUser, selectedDepartment, searchTerm]);

  useEffect(() => {
    dispatch(setUsers([]));
    fetchUsers();
  }, []);

  return (
    <div>
      <DataTable
        data={users}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        selectedDepartment={selectedDepartment}
        setSelectedDepartment={setSelectedDepartment}
      />
    </div>
  );
};

export default Users;
