import moment from 'moment';

const getRelativeTime = (commentDate) => {
  const today = moment();
  const date = moment(commentDate);

  // Check if the comment is from today
  if (date.isSame(today, 'day')) {
    return 'Today';
  }

  // Check if the comment is from yesterday
  if (date.isSame(today.subtract(1, 'day'), 'day')) {
    return 'Yesterday';
  }

  // Check if the comment is from 2 days ago
  if (date.isSame(today.subtract(1, 'day'), 'day')) {
    return '2d ago';
  }

  // Check if the comment is from within the last week
  if (today.diff(date, 'days') <= 7) {
    return `${today.diff(date, 'days')}d ago`;
  }

  // Check if the comment is from within the last month
  if (today.diff(date, 'weeks') <= 4) {
    return `${today.diff(date, 'weeks')}W ago`;
  }

  // If the comment is more than a month ago, use months
  if (today.diff(date, 'months') <= 12) {
    return `${today.diff(date, 'months')}M ago`;
  }

  // If it's more than a year ago, use years
  return `${today.diff(date, 'years')}Y ago`;
};

export default getRelativeTime;
