import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  OAuthProvider,
  getAuth,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import { app } from "../firebase/firebase";

const initialState = {
  user: JSON.parse(localStorage.getItem("user")) || null,
  error: null,
  isAuthinticated: false,
  isUserAdmin: false,
};

const setupMicrosoftProvider = () => {
  const provider = new OAuthProvider("microsoft.com");
  provider.setCustomParameters({
    // prompt: "consent",
    tenant: "48e89992-1e2b-4908-803a-7957e2cc1cbd", // "de731e79-68b6-4a05-81c3-3697fe23874f",
  });
  return provider;
};

export const loginAsyncWithMicrosoft = createAsyncThunk(
  "auth/loginWithMicrosoft",
  async () => {
    const provider = setupMicrosoftProvider();
    const auth = getAuth(app);
    const result = await signInWithPopup(auth, provider);
    return JSON.stringify(result);
  }
);

export const logoutAsync = createAsyncThunk("auth/logout", async () => {
  const auth = getAuth(app);
  const res = await signOut(auth);
  return JSON.stringify(res);
});

const authSlice = createSlice({
  name: "AuthSlice",
  initialState,
  reducers: {
    addUserInfo: (state, action) => {
      state.user = {
        ...state.user,
        ...action.payload,
      };
      localStorage.setItem("user", JSON.stringify(state.user));
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginAsyncWithMicrosoft.fulfilled, (state, action) => {
      console.debug("MS fullfulled loggin in...");
      const result = JSON.parse(action.payload);
      localStorage.setItem("user", JSON.stringify(result));
      localStorage.setItem("isAuthinticated", true);
      state.user = result;
      state.isAuthinticated = true;
    });
    builder.addCase(loginAsyncWithMicrosoft.rejected, (state, action) => {
      const error = JSON.parse(action.payload);
      console.error("Error: ", error);
      state.error = error;
      // dispatch({ type: LOGIN_FAILURE, payload: error });
    });
    builder.addCase(logoutAsync.fulfilled, (state, action) => {
      localStorage.removeItem("user");
      localStorage.removeItem("isAuthinticated");
      // state.isAuthinticated = false;
      // state.user = {};
      // state.error = {};
    });
    builder.addCase(
      logoutAsync.rejected,
      (state, action) => (state.error = JSON.parse(action.error))
    );
  },
});

export const authActions = authSlice.actions;
export default authSlice;
