import React, { useState } from "react";
import { useNavigate, Outlet } from "react-router-dom";
import { Timer, AssignmentTurnedIn, AssignmentLate } from "@mui/icons-material";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import InfoIcon from "@mui/icons-material/Info";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import { useEffect } from "react";

const ControlPanel = () => {
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState("departments");

  const handleItemClick = (item) => {
    setSelectedItem(item);
    navigate("" + item.toLowerCase());
  };

  useEffect(() => {
    navigate("departments");
  }, []);

  /*
Welcome to the Admin Dashboard! Here you can manage users, departments, and FAQs to keep your service desk running smoothly. Use the navigation menu to access different management features and maintain efficient operations.
  */

  return (
    <div id="control_panel">
      <div className="header-part">
        <h2>Control Panel</h2>
      </div>
      <div className="main-container">
        <ul className="control-panel-tabs">
          <li
            className={`tab-item ${
              selectedItem === "departments" ? "active-tab" : ""
            }`}
            onClick={() => handleItemClick("departments")}
          >
            <AccountBalanceIcon /> Departments
          </li>

          <li
            className={`tab-item ${
              selectedItem === "users" ? "active-tab" : ""
            }`}
            onClick={() => handleItemClick("users")}
          >
            <SupervisorAccountIcon /> Users
          </li>

          <li
            className={`tab-item ${
              selectedItem === "manage_faqs" ? "active-tab" : ""
            }`}
            onClick={() => handleItemClick("manage_faqs")}
          >
            <InfoIcon /> Manage FAQs
          </li>

          <li
            className={`tab-item ${
              selectedItem === "manage_slas" ? "active-tab" : ""
            }`}
            onClick={() => handleItemClick("manage_slas")}
          >
            <Timer />
            SLAs
          </li>
        </ul>
        <div className="content-container">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default ControlPanel;
