import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import DashboardIcon from "@mui/icons-material/Dashboard";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import PeopleIcon from "@mui/icons-material/People";
import QuizIcon from "@mui/icons-material/Quiz";
import ReportIcon from "@mui/icons-material/Report";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import AdminIcon from "@mui/icons-material/AdminPanelSettings";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import { globalActions } from "../slices/globalSlice";
import { useNavigate } from "react-router-dom";
import { fetchCategoriesAsync } from "../slices/CategorySlice";
import FeedbackIcon from "@mui/icons-material/Feedback";

const Sidebar = () => {
  const tab = useSelector((state) => state.global.tab);
  const categories = useSelector((state) => state.category.categories);
  const { user, userInfo } = useSelector((state) => state.auth.user);
  const [isAdmin, setIsAdmin] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (user && dispatch) {
      fetchCategoriesAsync(dispatch, user.email);
    }

    if (categories) {
      setIsAdmin(categories.length > 0);
    }
  }, [user, dispatch, categories]);

  const [canViewChats, setCanViewChats] = useState(false);
  const [canViewControlPanel, setCanViewControlPanel] = useState(false);

  const onChangeTabHandler = (tab) => {
    dispatch(globalActions.changeTab(tab));
    navigate("/" + tab);
  };

  useEffect(() => {
    setCanViewChats(
      userInfo?.isSuperAdmin || userInfo?.isAdmin || userInfo?.isSpecialist
    );
    setCanViewControlPanel(userInfo?.isSuperAdmin || userInfo?.isAdmin);
  }, [userInfo]);

  return (
    <div>
      <div className="side-bar">
        <div className="logo">
          <span>
            <span></span>
          </span>
          <h4>MyWSU Service Desk</h4>
        </div>
        <div className="nav-list">
          <button
            className={`${tab === "dashboard" && "active-btn"}`}
            onClick={() => onChangeTabHandler("dashboard")}
            type="button"
          >
            <DashboardIcon /> Dashboard
          </button>
          <button
            className={`${tab === "cases" && "active-btn"}`}
            onClick={() => onChangeTabHandler("cases")}
            type="button"
          >
            <ReportIcon /> Cases
          </button>
          {canViewChats ? (
            <button
              className={`${tab === "chats" && "active-btn"}`}
              onClick={() => onChangeTabHandler("chats")}
              type="button"
            >
              <QuestionAnswerIcon /> Chats
            </button>
          ) : null}
          {canViewControlPanel ? (
            <button
              className={`${tab === "control_panel" && "active-btn"}`}
              onClick={() => onChangeTabHandler("control_panel")}
              type="button"
            >
              <AdminPanelSettingsIcon /> Control Panel
            </button>
          ) : null}
          <button
            className={`${tab === "faqs" && "active-btn"}`}
            onClick={() => onChangeTabHandler("faqs")}
            type="button"
          >
            <QuizIcon /> FAQs
          </button>

          <button
            className={`${tab === "insights" && "active-btn"}`}
            onClick={() => onChangeTabHandler("insights")}
            type="button"
          >
            <FeedbackIcon /> Client Insights
          </button>

          <button
            className={`${tab === "account" && "active-btn"} my-account`}
            onClick={() => onChangeTabHandler("account")}
            type="button"
          >
            <AccountCircleIcon />
            <div>
              {userInfo.displayName}
              <span>Account Setting</span>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
