import React, { useState, useEffect } from "react";
import FeedbackIcon from "@mui/icons-material/Feedback";
import InsightsIcon from "@mui/icons-material/Insights";
import { Outlet, useNavigate } from "react-router-dom";
import { feedbackFetch } from "../../slices/api/feedbackAPI";
import { useDispatch, useSelector } from "react-redux";

const Insights = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authUser = useSelector((state) => state.auth);
  const { feedbackList } = useSelector((state) => state.feedback);
  const [selectedItem, setSelectedItem] = useState("surveys");
  const [unreadFeedback, setUnreadFeedback] = useState(0);

  useEffect(() => {
    navigate("surveys");
    dispatch(feedbackFetch());
  }, [dispatch]);

  const handleItemClick = (item) => {
    setSelectedItem(item);
    navigate("" + item.toLowerCase());
  };

  const isReadNotByLoggedInuser = (feedback) => {
    return (
      !feedback.feedbackReadByEmails ||
      !feedback.feedbackReadByEmails.includes(authUser.user.userInfo.mail)
    );
  };

  useEffect(() => {
    const count = feedbackList.filter((item) =>
      isReadNotByLoggedInuser(item)
    ).length;

    setUnreadFeedback(count);
  }, [feedbackList]);

  return (
    <div id="insights">
      <div className="header-part">
        <h2>Client Insights</h2>
      </div>
      <div className="main-container">
        <ul className="tabs-container">
          <li
            className={`tab-item ${
              selectedItem === "surveys" ? "active-tab" : ""
            }`}
            onClick={() => handleItemClick("surveys")}
          >
            <InsightsIcon /> Survey
          </li>

          <li
            className={`tab-item ${
              selectedItem === "feedback" ? "active-tab" : ""
            }`}
            onClick={() => handleItemClick("feedback")}
          >
            <FeedbackIcon /> Feedback{" "}
            {unreadFeedback > 0 && (
              <span className="badge bg-danger">{unreadFeedback}</span>
            )}
          </li>
        </ul>

        <div className="content-container">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Insights;
