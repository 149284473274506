import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    feedbackList: [],
};

const feedbackSlice = createSlice({
    name: 'feedback',
    initialState,
    reducers: {
        setFeedback: (state, action) => {
            state.loading = false;
            state.feedbackList = action.payload;
            
        }
    }
});

export const {
    setFeedback 
} = feedbackSlice.actions;

export default feedbackSlice;