import {
  collection,
  doc,
  getDoc,
  getFirestore,
  runTransaction,
  query,
  where,
  onSnapshot,
  addDoc,
  deleteDoc,
  arrayUnion,
  updateDoc,
  setDoc,
} from "firebase/firestore";
import { db } from "../../firebase/firebase";
import moment from "moment";
import { setActiveCase, setCases } from "../CasesSlice";
import axios from "axios";
import Constants from "../../constants/Constants";
const EMAIL_SERVICE_END_POINT =
  "https://wsfu2dtn6dg52ztqh6ud6xjlda0jholp.lambda-url.af-south-1.on.aws/"; //  "https://4elyea23mfngeb3czfojlfucgm0hfyum.lambda-url.eu-west-1.on.aws";

export const createCase = async (newCaseData) => {
  const firestore = getFirestore();
  const counterDocRef = doc(firestore, "metadata", "caseCounter");
  const casesCollectionRef = collection(firestore, "cases");

  const transaction = runTransaction(firestore, async (transaction) => {
    const counterDoc = await getDoc(counterDocRef);
    let currentCount = 0;

    if (counterDoc.exists()) {
      currentCount = counterDoc.data().count;
    }

    const newCount = currentCount + 1;

    transaction.set(counterDocRef, { count: newCount });

    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = moment(currentDate).format("MM");
    const formattedCount = newCount.toString().padStart(2, "0");
    const caseNumber = `#mYSd-${year}-${month}-${formattedCount}`;

    const newCaseDocRef = doc(casesCollectionRef);

    transaction.set(newCaseDocRef, {
      caseNumber,
      ...newCaseData,
    });

    return { caseNumber, id: newCaseDocRef.id }; // Return both case number and document ID
  });

  const result = await transaction;
  return result; // Return the object containing case number and ID
};

export const subscribeToCases = (dispatch, userDepartment, isSuperAdmin) => {
  const casesRef = collection(db, "cases");
  let q = null;

  if (isSuperAdmin) {
    q = query(
      casesRef,
      where("status", "not-in", [
        Constants.chatStages.ARCHIVED,
        Constants.chatStages.CLOSED,
      ])
    );
  } else {
    q = query(
      casesRef,
      where("status", "not-in", [
        Constants.chatStages.ARCHIVED,
        Constants.chatStages.CLOSED,
      ]),
      where("department", "==", userDepartment)
    );
  }

  const unsubscribe = onSnapshot(q, (snapshot) => {
    const cases = snapshot.docs.map((doc) => {
      let caseData = doc.data();

      return { id: doc.id, ...caseData };
    });

    dispatch(setCases(cases));
  });

  return unsubscribe;
};

export const subscribeToSingleCase = (dispatch, caseId) => {
  const caseRef = doc(db, "cases", caseId);

  const unsubscribe = onSnapshot(caseRef, (docSnapshot) => {
    if (docSnapshot.exists()) {
      const caseData = docSnapshot.data();

      // Convert Firestore Timestamps and handle Firestore-specific fields
      const updatedCase = {
        id: docSnapshot.id,
        ...caseData,
        // dateCreated: caseData.DateCreated ? caseData.DateCreated.toDate() : null,
        // dateUpdated: caseData.DateUpdated ? caseData.DateUpdated.toDate() : null,
      };

      // Dispatch only serializable data to Redux
      dispatch(setActiveCase(updatedCase));
    } else {
      // Handle document not found scenario
      console.log(`Document with caseId ${caseId} not found`);
      // Optionally, dispatch an action to handle the case being removed
      // dispatch(removeSingleCase(caseId));
    }
  });

  return unsubscribe;
};

export const updateCaseWithComment = async (caseId, payload) => {
  try {
    const db = getFirestore();
    const caseRef = doc(db, "cases", caseId);

    updateDoc(caseRef, {
      dateUpdated: new Date().toString(),
      comments: arrayUnion(payload),
    });
  } catch (error) {
    console.error("Error updating array:", error);
  }
};

export const escalateDepartment = async (caseId, caseData) => {
  try {
    const db = getFirestore();
    const caseRef = doc(db, "cases", caseId);

    const newAuditEntry = {
      auditType: Constants.auditType.DEPARTMENT_ESCALATION,
      dateCreated: new Date().toString(),
      actionPerson: caseData.currentAsignee,
      escalationData: {
        fromDepartment: caseData.currentDepartment,
        toDepartment: caseData.department,
        escalationReason: caseData.escalationReason,
      },
    };

    const updateData = {
      dateUpdated: new Date().toString(),
      department: caseData.department,
      category: null,
      auditAuditTrail: arrayUnion(newAuditEntry),
      assignedTo: [],
    };

    await updateDoc(caseRef, updateData);

    console.log(`Case ${caseId} escalated successfully.`);
  } catch (error) {
    console.error("Error updating case:", error);
    throw new Error("Error occurred while updating case");
  }
};

export const updateCaseMasterData = async (caseId, payload) => {
  try {
    const db = getFirestore();
    const caseRef = doc(db, "cases", caseId);

    updateDoc(caseRef, payload);
  } catch (error) {
    console.error("Error updating array:", error);
  }
};

export const sendCaseUpdateEmail = (
  receiverEmailAddresses,
  caseNumber,
  messageBody,
  caseLink
) => {
  const payload = {
    recipientEmailAddresses: receiverEmailAddresses,
    templateData: {
      caseNumber: caseNumber,
      message: messageBody,
      timestamp: moment().format("YYYY-MM-DD/HH:mm:ss"),
      userName: "User",
      caseLink: caseLink,
    },
    sourceEmail: "mywsu@wsu.ac.za",
    templateName: "case_update_template_v7",
  };

  return new Promise((resolve, reject) => {
    axios
      .post(
        EMAIL_SERVICE_END_POINT,
        payload
        //   ,
        //   {
        //   headers: headers,
        // }
      )
      .then((res) => {
        resolve("ok");
      })
      .catch((error) => {
        console.log("Error occurred: ", error);
        reject(error);
      });
  });
};
