import React, { useState, useEffect } from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  getUserDetailsByUserPrincipalName,
  updateUserByUserPrincipalName,
} from "../../../slices/api/usersApi";
import Constants from "../../../constants/Constants";
import { useDispatch, useSelector } from "react-redux";
import {
  setShowToast,
  setToastMessage,
  setToastTimer,
} from "../../../slices/ToastSlice";
import { logoutAsync } from "../../../slices/AuthSlice";
import { sendEmailApi } from "../../../services/commonService";
import { useNavigate } from "react-router-dom";
import { updateCategoryData } from "../../../slices/api/departmentsApi";

const UserDetails = ({
  userMail,
  userDepartment,
  users,
  departmentData,
  onBack,
}) => {
  const [user, setUserDetails] = useState({});
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isSpecialist, setIsSpecialist] = useState(false);
  const [originalRoles, setOriginalRoles] = useState({});
  const authUser = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const fetchUser = async () => {
      const userDetails = await getUserDetailsByUserPrincipalName(userMail);
      setUserDetails(userDetails);

      // Initialize role states after fetching user data
      setIsSuperAdmin(!!userDetails?.data?.isSuperAdmin);
      setIsAdmin(!!userDetails?.data?.isAdmin);
      setIsSpecialist(!!userDetails?.data?.isSpecialist);

      // Set original roles for comparison later
      setOriginalRoles({
        isSuperAdmin: !!userDetails?.data?.isSuperAdmin,
        isAdmin: !!userDetails?.data?.isAdmin,
        isSpecialist: !!userDetails?.data?.isSpecialist,
      });
    };

    if (userMail) {
      fetchUser();
    }
  }, [userMail]);

  const getRoleDescription = (roleName) => {
    switch (roleName) {
      case "Super Admin":
        return "This role grants the overall system administrative actions.";
      case "Department Admin":
        return "This role grants the user administrative actions in their departments.";
      case "Support First Line Agent":
        return "This role enables a user to attend incoming case reports, via calls and chat, and has the ability to assign cases within their department.";
      default:
        return "";
    }
  };

  const getAdminDetails = () => {
    const adminDetails = users.find(
      (item) => item.mail === authUser.user.userInfo.mail
    );

    return adminDetails ? authUser.user.userInfo.displayName : "Name Not found";
  };

  const handleSubmit = async () => {
    const hasUpdates =
      isSuperAdmin !== originalRoles.isSuperAdmin ||
      isAdmin !== originalRoles.isAdmin ||
      isSpecialist !== originalRoles.isSpecialist;

    if (hasUpdates) {
      try {
        const payload = {
          isSuperAdmin,
          isAdmin,
          isSpecialist,
          dateUpdated: new Date().toString(),
          auditTrail: {
            auditType: Constants.auditType.USER_TYPE_UPDATE,
            actionPerson: authUser.user.userInfo.mail,
            userTypeData: {
              from: originalRoles,
              to: {
                isSuperAdmin,
                isAdmin,
                isSpecialist,
              },
            },
            dateCreated: new Date().toString(),
          },
        };

        setIsSubmitting(true);

        await updateUserByUserPrincipalName(user.data.mail, payload);

        const sendRoleUpdateEmail = async (role, roleName) => {
          let actionType = role ? "Added" : "Removed";
          let templateDataForUser = {
            userName: "User",
            actionType,
            userRole: roleName,
            roleDescription: getRoleDescription(roleName),
            adminUpdated: getAdminDetails(),
            serviceDeskLink: `${window.location.protocol}//${window.location.hostname}:${window.location.port}`,
          };

          const emailPayloadForUser = {
            recipientEmailAddresses: [user.data.mail],
            templateData: templateDataForUser,
            sourceEmail: Constants.sourceEmailList.NO_REPLY,
            templateName: "role_updated",
          };

          if (emailPayloadForUser.recipientEmailAddresses.length > 0) {
            try {
              await sendEmailApi(emailPayloadForUser);
            } catch (e) {
              console.log("Status - 500", e);
            }
          }
        };

        if (isSuperAdmin !== originalRoles.isSuperAdmin) {
          await sendRoleUpdateEmail(isSuperAdmin, "Super Admin");
        }

        if (isAdmin !== originalRoles.isAdmin) {
          await sendRoleUpdateEmail(isAdmin, "Department Admin");

          let departmentAdmins = { ...departmentData.admins } || {};

          if (!isAdmin) {
            const userEmail = user.data.mail;
            const updatedAdmins = { ...departmentAdmins };

            if (updatedAdmins.hasOwnProperty(userEmail)) {
              try {
                delete updatedAdmins[userEmail];
              } catch (e) {
                console.log("Error removing admin:", e);
              }
            } else {
              console.log(`Admin "${userEmail}" not found.`);
            }
            departmentAdmins = updatedAdmins;
          } else {
            departmentAdmins[user.data.mail] = new Date().toString();
          }

          await updateCategoryData(departmentData.id, {
            admins: departmentAdmins,
          });
        }

        if (isSpecialist !== originalRoles.isSpecialist) {
          await sendRoleUpdateEmail(isSpecialist, "Support First Line Agent");
        }

        setIsSubmitting(false);

        dispatch(setToastMessage("Successfully updated user type."));
        dispatch(setToastTimer(3000));
        dispatch(setShowToast(true));

        if (authUser.user.userInfo.mail === user.data.mail) {
          dispatch(logoutAsync());
          navigate("/");
        } else {
          onBack();
        }
      } catch (error) {
        console.log(error);
        dispatch(
          setToastMessage("Error occurred while trying to update user type.")
        );
        dispatch(setToastTimer(3000));
        dispatch(setShowToast(true));
        setIsSubmitting(false);
      }
    } else {
      dispatch(setToastMessage("No update made."));
      dispatch(setToastTimer(3000));
      dispatch(setShowToast(true));
    }
  };

  const handleSuperAdminChange = (event) => {
    setIsSuperAdmin(event.target.checked);
  };

  const handleAdminChange = (event) => {
    setIsAdmin(event.target.checked);
  };

  const handleSpecialistChange = (event) => {
    setIsSpecialist(event.target.checked);
  };

  const handleCancel = () => {
    setIsSuperAdmin(originalRoles.isSuperAdmin);
    setIsAdmin(originalRoles.isAdmin);
    setIsSpecialist(originalRoles.isSpecialist);
  };

  return (
    <div>
      <button type="button" className="back-btn" onClick={onBack}>
        <ChevronLeftIcon /> Back
      </button>
      <div className="user-details">
        <h2>
          {user.data?.givenName} {user.data?.surname}
        </h2>
        <table>
          <tbody>
            <tr>
              <td>Email:</td>
              <th>{user.data?.mail}</th>
            </tr>
            <tr>
              <td>Phone:</td>
              <th>{user.data?.mobilePhone}</th>
            </tr>
            <tr>
              <td>Location:</td>
              <th>{user.data?.officeLocation}</th>
            </tr>
            <tr>
              <td>Department:</td>
              <th>{userDepartment}</th>
            </tr>
            <tr>
              <td>City:</td>
              <th>{user.data?.city}</th>
            </tr>
          </tbody>
        </table>

        <div className="checkbox-container">
          <div>
            <input
              type="checkbox"
              id="super_admin_chk"
              checked={isSuperAdmin}
              onChange={handleSuperAdminChange}
            />
            <label htmlFor="super_admin_chk">Super Admin</label>
          </div>
          <div>
            <input
              type="checkbox"
              checked={isAdmin}
              id="admin_chk"
              onChange={handleAdminChange}
            />
            <label htmlFor="admin_chk">Department Admin</label>
          </div>
          <div>
            <input
              type="checkbox"
              id="technician_chk"
              checked={isSpecialist}
              onChange={handleSpecialistChange}
            />
            <label htmlFor="technician_chk">Help Desk Officer</label>
          </div>
        </div>

        {(originalRoles.isAdmin !== isAdmin ||
          originalRoles.isSuperAdmin !== isSuperAdmin ||
          originalRoles.isSpecialist !== isSpecialist) && (
          <div className="action-btns">
            <button
              className="cancel-btn"
              type="button"
              onClick={handleCancel}
              disabled={isSubmitting}
            >
              Cancel
            </button>
            <button
              className="save-btn"
              type="submit"
              onClick={handleSubmit}
              disabled={isSubmitting}
            >
              Save Changes
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserDetails;
