import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  InputAdornment,
  TableSortLabel,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Button,
} from "@mui/material";
import { Search, Clear } from "@mui/icons-material";
import UserDetailsModal from "./UserDetailsModal";
import { useDispatch, useSelector } from "react-redux";
import { subscribeToDepartments } from "../../../slices/api/departmentsApi";
import Constants from "../../../constants/Constants";
import DepartmentAutocomplete from "./DepartmentAutocomplete";

const DataTable = ({
  data,
  searchTerm,
  setSearchTerm,
  selectedDepartment,
  setSelectedDepartment,
}) => {
  const authUser = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [selectedUser, setSelectedUser] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });

  const { departmentsList } = useSelector((state) => state.departments);

  // Pagination State
  const [currentPage, setCurrentPage] = useState(0);
  // const itemsPerPage = 3;
  const [itemsPerPage, setItemsPerPage] = useState(3);

  useEffect(() => {
    const { isAdmin, department } = authUser.user.userInfo;
    subscribeToDepartments(dispatch, isAdmin, department);
  }, [dispatch, authUser]);

  useEffect(() => {
    // Calculate the number of items per page based on 35% of the viewport height
    const calculateItemsPerPage = () => {
      const viewportHeight = window.innerHeight;
      const percentageHeight = viewportHeight * 0.35; // 35% of the view height
      const rowHeight = 50; // Assume each row height is 50px (you can adjust this based on actual row height)
      const calculatedItemsPerPage = Math.floor(percentageHeight / rowHeight);

      setItemsPerPage(calculatedItemsPerPage);
    };

    // Run the calculation once on component mount
    calculateItemsPerPage();

    // Recalculate items per page on window resize
    window.addEventListener("resize", calculateItemsPerPage);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", calculateItemsPerPage);
    };
  }, []);

  // Reset pagination when data changes
  useEffect(() => {
    setCurrentPage(0); // Reset to page 1 (zero-based index)
  }, [data]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleDepartmentChange = (id) => {
    setSelectedDepartment(id);
  };

  const handleSort = (key) => {
    setSortConfig((prev) => ({
      key,
      direction: prev.key === key && prev.direction === "asc" ? "desc" : "asc",
    }));
  };

  const handleViewSingleUser = (user) => {
    setSelectedUser(user);
  };

  const getDepartmentByName = (item) => {
    try {
      return (
        departmentsList.find((dep) => dep.id === item.department)?.name ||
        item.department
      );
    } catch (e) {
      return "Unknown";
    }
  };

  const sortedAndFilteredData = data
    .filter((item) => {
      const { department, givenName, surname } = item;
      return true; // You can re-enable your filtering logic here
    })
    .sort((a, b) => {
      const { key, direction } = sortConfig;
      if (direction === "asc") {
        return a[key]?.localeCompare(b[key]);
      } else if (direction === "desc") {
        return b[key]?.localeCompare(a[key]);
      }
      return 0;
    });

  // Paginated Data
  const paginatedData = sortedAndFilteredData.slice(
    currentPage * itemsPerPage,
    currentPage * itemsPerPage + itemsPerPage
  );

  // Total Pages
  const totalPages = Math.ceil(sortedAndFilteredData.length / itemsPerPage);

  // Pagination Handlers
  const handleNextPage = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div>
      <div>
        <TextField
          label="Search"
          variant="outlined"
          value={searchTerm}
          onChange={handleSearch}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {searchTerm && (
                  <Clear
                    onClick={() => setSearchTerm("")}
                    style={{ cursor: "pointer" }}
                  />
                )}
                <Search />
              </InputAdornment>
            ),
          }}
        />
        <FormControl
          variant="outlined"
          style={{ marginLeft: "20px", width: "300px" }}
        >
          <DepartmentAutocomplete
            departmentsList={departmentsList}
            selectedDepartment={selectedDepartment}
            handleDepartmentChange={handleDepartmentChange}
          />
        </FormControl>
      </div>

      <hr />

      {selectedUser === null && (
        <>
          {sortedAndFilteredData.length === 0 ? (
            <p>No users found.</p>
          ) : (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <TableSortLabel
                        active={sortConfig.key === "givenName"}
                        direction={
                          sortConfig.key === "givenName"
                            ? sortConfig.direction
                            : "asc"
                        }
                        onClick={() => handleSort("givenName")}
                      >
                        First Name
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortConfig.key === "surname"}
                        direction={
                          sortConfig.key === "surname"
                            ? sortConfig.direction
                            : "asc"
                        }
                        onClick={() => handleSort("surname")}
                      >
                        Last Name
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>Mobile Phone</TableCell>
                    <TableCell>Office Location</TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortConfig.key === "department"}
                        direction={
                          sortConfig.key === "department"
                            ? sortConfig.direction
                            : "asc"
                        }
                        onClick={() => handleSort("department")}
                      >
                        Department
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>Campus</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedData.map((item) => (
                    <TableRow
                      key={item.id}
                      onClick={() => handleViewSingleUser(item)}
                    >
                      <TableCell>{item.givenName}</TableCell>
                      <TableCell>{item.surname}</TableCell>
                      <TableCell>{item.mobilePhone}</TableCell>
                      <TableCell>{item.officeLocation}</TableCell>
                      <TableCell>{getDepartmentByName(item)}</TableCell>
                      <TableCell>{item.city}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {/* Pagination Controls */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "20px",
            }}
          >
            <Button
              onClick={handlePreviousPage}
              disabled={currentPage === 0}
              variant="outlined"
            >
              Previous
            </Button>
            <div>
              Page {currentPage + 1} of {totalPages}
            </div>
            <Button
              onClick={handleNextPage}
              disabled={currentPage === totalPages - 1}
              variant="outlined"
            >
              Next
            </Button>
          </div>
        </>
      )}

      {selectedUser && (
        <UserDetailsModal
          userMail={selectedUser.mail}
          userDepartment={selectedUser.department}
          user={selectedUser}
          users={data}
          departmentName={
            departmentsList.find((dep) => dep.id === selectedUser.department)
              ?.name
          }
          departmentData={departmentsList.find(
            (dep) => dep.id === selectedUser.department
          )}
          onBack={() => setSelectedUser(null)}
        />
      )}
    </div>
  );
};

export default DataTable;
