export const formatDate = (date) => {
  return new Date(date).toLocaleDateString("en-ZA", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

export const formatStatus = (inputString) => {
  const parts = inputString.split("_");
  const wordToFormat = parts.length > 1 ? parts[parts.length - 1] : inputString;
  const formattedWord =
    wordToFormat.charAt(0).toUpperCase() + wordToFormat.slice(1).toLowerCase();

  return formattedWord;
};

export const validateRequiredFields = (data, requiredFields) => {
  for (const field of requiredFields) {
    if (
      !data.hasOwnProperty(field) ||
      data[field] === "" ||
      data[field] === null ||
      data[field] === undefined
    ) {
      return false;
    }
  }
  return true;
};

export const formatDepartmentName = (deptName) => {
  // Replace special characters with a space
  let formattedName = deptName.replace(/[_;:,-]/g, " ");

  // Split into words, convert to lowercase, and capitalize the first letter of each word
  formattedName = formattedName
    .toLowerCase()
    .split(" ")
    .map((word) => {
      // Capitalize first letter and keep the rest lowercase
      if (word.length > 0) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      }
      return word;
    })
    .join(" ");

  return formattedName;
};

export const transformUnderscoreStringToTitle = (input) => {
  let words = input.split("_");

  let formattedWords = words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");

  return formattedWords;
};
