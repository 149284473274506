import React, { useEffect, useState } from "react";
import {
  feedbackFetch,
  updateFeedbackIsRead,
} from "../../slices/api/feedbackAPI";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

const FeedbackView = () => {
  const dispatch = useDispatch();
  const { feedbackList } = useSelector((state) => state.feedback);
  const [selectedFeedback, setSelectedFeedback] = useState(null);
  const authUser = useSelector((state) => state.auth);

  useEffect(() => {
    if (dispatch) {
      feedbackFetch(dispatch);
    }
  }, [dispatch]);

  const handleFeedbackClick = (feedback) => {
    setSelectedFeedback(feedback);
    if (
      !feedback.feedbackReadByEmails ||
      !feedback.feedbackReadByEmails.includes(authUser.user.userInfo.mail)
    ) {
      updateFeedbackIsRead(feedback.id, authUser.user.userInfo.mail);
    }
  };

  const isReadNotByLoggedInuser = (feedback) => {
    return (
      !feedback.feedbackReadByEmails ||
      !feedback.feedbackReadByEmails.includes(authUser.user.userInfo.mail)
    );
  };

  return (
    <div className="feedback-view">
      <h4>Feedback</h4>
      <div className="feedback-container">
        <div className="list-side">
          {feedbackList.length > 0 ? (
            feedbackList.map((feedback, index) => (
              <div
                className={`feedback-card shadow-sm ${
                  isReadNotByLoggedInuser(feedback) ? "is-not-read" : ""
                } ${selectedFeedback?.id === feedback.id ? "selected" : ""}`}
                key={index}
                onClick={() => handleFeedbackClick(feedback)}
              >
                <div className="card-header">
                  <h4>
                    {feedback.sender === "CLIENT"
                      ? "Anonymous"
                      : feedback.sender}
                  </h4>
                  <span className="date">
                    {moment(feedback.dateCreated).add(2, "hours").fromNow()}
                  </span>
                </div>
                <p
                  dangerouslySetInnerHTML={{ __html: feedback.feedbackContent }}
                ></p>
                {isReadNotByLoggedInuser(feedback) && (
                  <span className="not-read"></span>
                )}
              </div>
            ))
          ) : (
            <p>There are no feedback found</p>
          )}
        </div>
        <div className="single-feedback">
          {selectedFeedback ? (
            <div className="selected-feedback">
              <div className="card-header">
                <h4>
                  {selectedFeedback.sender === "CLIENT"
                    ? "Anonymous"
                    : selectedFeedback.sender}
                </h4>
              </div>
              <p
                dangerouslySetInnerHTML={{
                  __html: selectedFeedback.feedbackContent,
                }}
              ></p>
              <span className="date">
                {moment(selectedFeedback.dateCreated)
                  .add(2, "hours")
                  .format("MMMM DD, YYYY")}
              </span>
            </div>
          ) : (
            <p>Select a feedback to view details</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default FeedbackView;
