import React, { useEffect, useState } from "react";
import { Autocomplete, TextField, CircularProgress } from "@mui/material";
import { getAllUsersByDepartmentId } from "../../slices/api/usersApi";

const Assignee = ({
  activeCase,
  handleChange,
  selectedUsers,
  setSelectedUsers,
}) => {
  const [usersState, setUsersState] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getUsers = async () => {
      try {
        const users = await getAllUsersByDepartmentId(activeCase.department);
        setUsersState(users);
      } catch (error) {
        console.error("Failed to fetch users", error);
      } finally {
        setLoading(false);
      }
    };
    getUsers();
  }, [activeCase.department]);

  useEffect(() => {
    if (usersState.length > 0 && activeCase?.assignedTo) {
      const assignedUsers = getUserDetails(usersState, activeCase.assignedTo);
      setSelectedUsers(assignedUsers);
    }
  }, [usersState, activeCase]);

  const getUserDetails = (usersList, assignedEmails) => {
    return usersList.filter((user) => assignedEmails.includes(user.mail));
  };

  return (
    <>
      {loading ? (
        <CircularProgress />
      ) : (
        <Autocomplete
          multiple
          limitTags={5}
          onChange={handleChange}
          options={usersState.filter(
            (user) => user.department === activeCase.department
          )}
          getOptionLabel={(user) => `${user.givenName} ${user.surname}`}
          getOptionSelected={(option, value) => option.mail === value.mail}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="-- Select Assignees --"
              variant="outlined"
            />
          )}
          value={selectedUsers}
          required
        />
      )}
    </>
  );
};

export default Assignee;
