import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { subscribeToSurveys } from "../../slices/api/surveyApi";

const SurveysView = () => {
  const dispatch = useDispatch();
  const authUser = useSelector((state) => state.auth);
  const surveys = useSelector((state) => state.surveys);

  useEffect(() => {
    let unsubscribe = false;

    if (authUser !== null && authUser !== undefined) {
      const isSuperAdmin = authUser.user.userInfo.isSuperAdmin ? true : false;

      unsubscribe = subscribeToSurveys(
        dispatch,
        authUser.user.userInfo.department,
        isSuperAdmin
      );
    }

    return () => {
      if (typeof unsubscribe === "function") {
        unsubscribe();
      }
    };
  }, [dispatch, authUser]);
  return (
    <div className="surveys">
      <h4>Surveys</h4>
      <div className="surveys-container">
        {surveys && surveys.surveyAnswersList.length > 0 ? (
          surveys.surveyAnswersList.map((survey, index) => (
            <div key={index}>
              <p>
                Answered at: {new Date(survey.dateUpdated).toLocaleString()}
              </p>

              {survey.answers && Object.keys(survey.answers).length > 0 ? (
                Object.entries(survey.answers).map(
                  ([question, answer], answerIndex) => (
                    <p key={answerIndex}>
                      <strong>{question}:</strong>{" "}
                      {answer.charAt(0).toUpperCase() + answer.slice(1)}
                    </p>
                  )
                )
              ) : (
                <p>
                  No answers available.{" "}
                  {survey.answers && JSON.stringify(survey.answers)}
                </p>
              )}
            </div>
          ))
        ) : (
          <p>No surveys available.</p>
        )}
      </div>
    </div>
  );
};

export default SurveysView;
