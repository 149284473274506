import React, { useEffect, useState } from "react";
import moment from "moment";
import InfoIcon from "@mui/icons-material/Info";
import { getAllUsers } from "../../../slices/api/usersApi";
import { useDispatch, useSelector } from "react-redux";
import NameResolver from "../../common/NameResolver";
import ConfirmationDialog from "../../ConfirmationDialog";
import { updateCategoryData } from "../../../slices/api/departmentsApi";
import {
  setShowToast,
  setToastMessage,
  setToastTimer,
} from "../../../slices/ToastSlice";
import { formatDepartmentName } from "../../../utils";

const CategoryCard = ({ category, onClickCategory, departmentsList }) => {
  const users = useSelector((state) => state.user.users);
  const authUser = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const [feature, setFeature] = useState("");
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [callbackFunction, setCallbackFunction] = useState(null);

  useEffect(() => {
    if (category && authUser) {
      // getAllUsers(
      //   dispatch,
      //   authUser.user.userInfo.isAdmin,
      //   authUser.user.userInfo.department
      // );
    }
  }, [dispatch, authUser]);

  const handleCategoryClick = (event) => {
    if (
      event.target.tagName.toLowerCase() !== "input" &&
      event.target.tagName.toLowerCase() !== "button"
    ) {
      onClickCategory(category);
    }
  };

  const getNumberOfAgentsOnDepartment = () => {
    const agents = null;
    //  users.filter(
    //   (user) => user.department == category.id && user.isSupportAgent
    // );
    return agents ? agents.length : 0;
  };

  const getNumberOfSubCategories = () => {
    const subCategories = departmentsList.filter(
      (department) => department.parentCategory == category.id
    );
    return subCategories ? subCategories.length : 0;
  };

  const handleFeatureUpdate = (
    featureName,
    categoryID,
    currentStatus,
    updateFunction
  ) => {
    setFeature(featureName);

    if (!currentStatus) {
      setCallbackFunction(
        () => () => updateFunction(categoryID, currentStatus)
      );
      setShowConfirmationDialog(true);
    } else {
      updateFunction(categoryID, currentStatus);
    }
  };

  const handleCategoryChatEnablement = async (id, value) => {
    try {
      const payload = {
        chatEnabled: value,
        dateUpdated: new Date().toString(),
      };
      await updateCategoryData(id, payload);
      dispatch(setToastMessage("Successfully updated the department."));
      dispatch(setToastTimer(3000));
      dispatch(setShowToast(true));
    } catch (error) {
      console.error(error);
      dispatch(
        setToastMessage(
          "An error occurred while trying to update the department."
        )
      );
      dispatch(setToastTimer(3000));
      dispatch(setShowToast(true));
    }
  };

  const handleCategoryCaseEnablement = async (id, value) => {
    try {
      const payload = {
        casesEnabled: value,
        dateUpdated: new Date().toString(),
      };
      await updateCategoryData(id, payload);
      dispatch(setToastMessage("Successfully updated the department."));
      dispatch(setToastTimer(3000));
      dispatch(setShowToast(true));
    } catch (error) {
      console.error(error);
      dispatch(
        setToastMessage(
          "An error occurred while trying to update the department."
        )
      );
      dispatch(setToastTimer(3000));
      dispatch(setShowToast(true));
    }
  };

  return (
    <>
      <div className="department-card shadow">
        <div className="card-header">
          <h2>{formatDepartmentName(category.name)}</h2>
          <div className="card-stats" onClick={handleCategoryClick}>
            <div className="stat">
              <span>Categories</span>
              <h4>{getNumberOfSubCategories()}</h4>
            </div>
            {/* <div className="stat">
              <span>Agents</span>
              <h4>{getNumberOfAgentsOnDepartment()}</h4>
            </div> */}
          </div>
          <div className="card-details" onClick={handleCategoryClick}>
            <table>
              <tr>
                <td>Admins</td>
                {category.admins && Object.keys(category.admins).length > 0
                  ? Object.keys(category.admins).map((adminEmail) => (
                      <NameResolver email={adminEmail} />
                    ))
                  : null}
              </tr>
              <tr>
                <td>Date Created</td>
                <th>
                  {moment(new Date(category.dateCreated))
                    .add(2, "hours")
                    .format("YYYY:MM:DD - h:mm A")}
                </th>
              </tr>
              <tr>
                <td>Date Updated</td>
                <th>
                  {moment(new Date(category.dateUpdated))
                    .add(2, "hours")
                    .format("YYYY:MM:DD - h:mm A")}
                </th>
              </tr>
            </table>
          </div>
          <div className="action-btns">
            <div className="check-item">
              <label htmlFor="chat_ckb">Live Chat</label>
              <input
                type="checkbox"
                name="chat"
                id="chat_ckb"
                checked={category.chatEnabled}
                onChange={(event) =>
                  handleFeatureUpdate(
                    "Chat",
                    category.id,
                    event.target.checked,
                    handleCategoryChatEnablement
                  )
                }
              />
            </div>

            <div className="check-item">
              <label htmlFor="cases_ckb">Cases</label>
              <input
                type="checkbox"
                name="cases"
                id="cases_ckb"
                checked={category.casesEnabled}
                onChange={(event) =>
                  handleFeatureUpdate(
                    "Cases",
                    category.id,
                    event.target.checked,
                    handleCategoryCaseEnablement
                  )
                }
              />
            </div>
          </div>
          <button
            type="button"
            className="info-btn"
            onClick={handleCategoryClick}
          >
            <InfoIcon />
          </button>
        </div>
      </div>
      {showConfirmationDialog && (
        <ConfirmationDialog
          message={`Are you sure you want to disable ${feature}?`}
          onCallback={callbackFunction}
          onCancel={() => setShowConfirmationDialog(false)}
          buttonText="Disable"
          buttonClassName="delete-btn"
        />
      )}
    </>
  );
};

export default CategoryCard;
