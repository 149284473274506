import {
  collection,
  getDocs,
  doc,
  getDoc,
  updateDoc,
  addDoc,
  onSnapshot,
  where,
  query,
} from "firebase/firestore";
import { db } from "../../firebase/firebase";
import { setSurveyAnswersList } from "../SurveysSlice";

export async function getAllSurveyQuestions() {
  const surveyRef = collection(db, "survey_questions");
  const surveySnapshot = await getDocs(surveyRef);

  const surveyQuestions = [];
  surveySnapshot.forEach((doc) => {
    let data = doc.data();
    data.id = doc.id;
    surveyQuestions.push(data);
  });
  console.log(surveyQuestions);
  return surveyQuestions;
}

export const getSurveyById = async (surveyAnswerId) => {
  try {
    const surveyRef = doc(db, "surveys", surveyAnswerId);

    const surveyAnswerSnapshot = await getDoc(surveyRef);

    if (surveyAnswerSnapshot.exists()) {
      const surveyData = {
        id: surveyAnswerSnapshot.id,
        ...surveyAnswerSnapshot.data(),
      };

      return surveyData;
    } else {
      throw new Error("Survey not found");
    }
  } catch (error) {
    throw new Error(error);
  }
};

export const saveSurveyAnswers = async (surveyAnswerId, surveyAnswers) => {
  try {
    const surveyRef = doc(db, "surveys", surveyAnswerId);

    return updateDoc(surveyRef, {
      dateUpdated: new Date().toString(),
      answers: surveyAnswers,
    });
  } catch (error) {
    console.error("Error updating survey answers document:", error);
    throw new Error(error);
  }
};

export const createSurvey = async (payload) => {
  try {
    const surveyRef = collection(db, "surveys");
    const docRef = await addDoc(surveyRef, payload);

    return docRef.id;
  } catch (error) {
    console.error("Error creating survey document:", error);
    throw new Error(error);
  }
};

export const subscribeToSurveys = (dispatch, userDepartment, isSuperAdmin) => {
  const surveyAnswersRef = collection(db, "surveys");
  let q = null;

  if (isSuperAdmin) {
    q = query(surveyAnswersRef);
  } else {
    q = query(surveyAnswersRef, where("department", "==", userDepartment));
  }

  const unsubscribe = onSnapshot(q, (snapshot) => {
    const surveyAnswers = snapshot.docs.map((doc) => {
      let surveyAnswerData = doc.data();

      return { id: doc.id, ...surveyAnswerData };
    });

    dispatch(setSurveyAnswersList(surveyAnswers));
  });

  return unsubscribe;
};
